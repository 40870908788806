import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ApiServiceService } from '../../../../services/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class RewardedService {

  currentBalance!: any;
  constructor(private _ApiService: ApiServiceService) { }

  GetRewardedPointsList(model: any) {
    return this._ApiService.get(`${environment.apiUrl}/rewarding-points`, model)
      .pipe(catchError(this.handleError));
  }


  getCompanyUsers(filterModel: any) {
    return this._ApiService.get(`${environment.apiUrl}/departments/search-users`, filterModel)
      .pipe(catchError(this.handleError));
  }
  
  getRewardingPointsUsers(filterModel: any) {
    return this._ApiService.get(`${environment.apiUrl}/departments/rewarding-points-users`, filterModel)
      .pipe(catchError(this.handleError));
  }
  sendPoints(data: any) {
    return this._ApiService.post(`${environment.apiUrl}/rewarding-points`, data)
    .pipe(catchError(this.handleError));
  }

  // set currentBalanceToSendMoney(currentBalance) {
  //   this.currentBalance = currentBalance;
  // }

  // get currentBalanceToSendMoney() {
  //   return this.currentBalance;
  // }

  handleError(error: any) {
    return throwError(error);
  }
}
